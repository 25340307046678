footer {
    border-top: 1px solid #e7e7e7;
}

body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    color: #333;
}

h1, h2, h3 {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}

h1 {
    font-size: 1.4em;
}

h2 {
    font-size: 1.2em;
}

h3 {
    font-size: 1em;
}

a {
  color: #337ab7;
}
